<template>
    <alliances-module-component></alliances-module-component>
</template>

<script>
    import AlliancesModuleComponent from "@/components/admin/modules/AlliancesModuleComponent";
    
    export default {
        name: "AlliancesModule",
        title: "Alianzas Estratégicas | Turismo BC",
        components: {AlliancesModuleComponent}
    }
</script>

<style scoped>

</style>